// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$backoffice-primary: mat.define-palette(mat.$indigo-palette);
$backoffice-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$backoffice-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$backoffice-theme: mat.define-light-theme((color: (primary: $backoffice-primary,
        accent: $backoffice-accent,
        warn: $backoffice-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($backoffice-theme);

:root {
  --elzab-color-primary: 29, 37, 45; //black
  --elzab-color-secondary: 229, 49, 56; //red
  --elzab-color-tertiary: 255, 255, 255; //white
  --elzab-color-quaternary: 00, 00, 00; //black

  --color-success-rgb: 62, 180, 74;
  --color-warning-rgb: 252, 177, 15;
  --color-danger-rgb: 229, 49, 56;
  --color-unknown-rgb: 183, 183, 183;

  --page-menu-width-close: 60px;
  --page-menu-width-open: 280px;

  --color-primary: rgb(var(--elzab-color-primary));
  --color-secondary: rgb(var(--elzab-color-secondary));
  --color-tertiary: rgb(var(--elzab-color-tertiary));
  --color-quaternary: rgb(var(--elzab-color-quaternary));

  --page-menu-background: var(--color-primary);
  --page-header-background: #fafbfc;
  --page-background: #ffffff;

  --color-success: rgb(var(--color-success-rgb));
  --color-warning: rgb(var(--color-warning-rgb));
  --color-danger: rgb(var(--color-danger-rgb));
  --color-unknown: rgb(var(--color-unknown-rgb));

  --background: #ffffff;

  --tile-background: rgb(253, 253, 253);
  --tile-border: 1px solid rgb(230, 230, 230);

  --gap: 20px;

  --color-skeleton: #f3f4f5;
  --color-skeleton_1: #f5f5f5;
  --color-skeleton_2: #eaeaea;

  --component-width-1: 700px;
  --component-width-2: 1090px;

  --shadow-box_1: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  min-height: 100vh;
}

body {
  color: var(--color-primary);
  font-family: 'Roboto', 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin: 0;

  &.overlay {
    overflow: hidden;
  }

  .mat-menu-panel {
    max-width: none !important;
    margin-right: -10px;
    margin-top: 11px;

    .mat-menu-content:not(:empty) {
      padding: 0;
    }
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.responsive {
  display: block;
  height: auto;
  width: 100%;
}

.mat-checkbox-label {
  white-space: pre-line;
}

.mat-success {
  background-color: green;
  color: #fff;
}

.mat-failure {
  background-color: red;
  color: #fff;
}

.mat-tooltip {
  background-color: var(--color-primary);
  font-size: 12px;
}